import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiUrlHelper {
  public apiUrl = {
    Login: {
      UserLogin: 'admin/account/login',
      logout: 'admin/account/logout'
    },
    Register:{
      RegisterUser: 'admin/user/saveby-user'
    },
    Role:{
      GetRoleList: 'admin/role/role-list',
      GetRoleById: 'admin/role/getrole/{Id}',
      SaveUpdateRole: 'admin/role/save',
      DeleteRole: 'admin/role/delete/{Id}',
      GetRole: 'admin/role/role-list-drop-down'
    },
    User:{
      GetUserList: 'admin/user/list',
      GetUserById: 'admin/user/get-user/{Id}',
      DeleteUser: 'admin/user/delete/{Id}',
      UserActiveDeactive: 'admin/user/active-inactive/{Id}',
      UserDropDownList: 'admin/user/user-dropdown-list',
      AddUpdateUser: 'admin/user/useradd-admin',
    },
    Country:{
      GetCountryList:'admin/country/list'
    },
    State:{
      GetStateList:'admin/state/list/{Id}'
    },
    city:{
      GetCityList: 'admin/city/list/{Id}'
    },
    Customer:{
      GetCustomerList:'admin/customer/list',
      GetCustomerById: 'admin/customer/get-customer-admin/{Id}',
      DeleteCustomer: 'admin/customer/delete/{Id}',
      AddUpdateCustomer: 'admin/customer/customeradd-admin',
      CustomerActiveDeactive: 'admin/customer/active-inactive/{Id}',
      Export: 'admin/customer/export'
    },
    CustomerActivities:{
      GetCustomerSessionList:'admin/customer/booked-session-list',
      GetCustomerEventList:'admin/customer/booked-event-list',
      GetCustomerReportRefundList:'admin/report/customer-report-list',
    },
    Payment:{
      GetPaymentTypeList: 'admin/payment-pass/list',
      GetPaymentTypeByID: 'admin/payment-pass/getpayment-pass/{Id}',
      SaveUpdatePaymentType: 'admin/payment-pass/save',
      DeletePaymentType: 'admin/payment-pass/delete/{Id}',
      PassTypeList: 'admin/setting/social-media/list/{Id}',
      GetPassTransactionDetails:'admin/payment-pass/get-pass-transaction-details',
      GetPassList: 'admin/payment-pass/get-pass-list/{Id}/{ClassSessionId}',
    },
    MyAccount:{
      UpdateUser: 'admin/user/updateby-user'
    },
    Class:{
      GetClassList: 'admin/class/list',
      GetClassById: 'admin/class/class-details/{Id}',
      GetRecentSessionListByClassId: 'admin/class/session-list',
      GetPastSessionListByClassId: 'admin/class/past-session-list',
      GetSessionAttendanceList: 'admin/class/session-attendance-list/{Id}',
      GetSessionCustomerList: 'admin/class/session-details',
      DeleteClass: 'admin/class/delete-class/{Id}',
      DeleteSession: 'admin/class/delete-session/{Id}',
      SaveClass: 'admin/class/save',
      GetClassConfirmation : 'admin/class/check-session-conflict',
      SaveSessionAttendance : 'admin/class/save-session-attendance',
      GetUpcomingSessionForViewMore: 'admin/class/upcoming-list-by-day',
      GetUpcomingSessionForCustomer: 'admin/class/upcoming-list',
      Export: 'admin/class/export',
      ExtendClassSession:'admin/class/extend-class-session',
      GetCustomerListNotExistOnSession:'admin/class/customer-list',
      GetClassSessionDetailBasedOnClassSessionId:'admin/class/class-session-detail/{Id}',
      GetPassList: 'customer/payment-pass/list/{Id}',
      BookSession: 'admin/order/book-session',
      AddCustomerInSession:'admin/class/add-customer-in-session',
      ResendPaymentLink:'admin/class/resend-payment-link',
      GetHostList :'admin/class/host-list/{listType}',
      GetFreezeUnFreeseSessionList :'admin/class/freeze-unfreeze-session-list',
      FreezeHost :'admin/class/freeze-host',
      UnfreezeSession :'admin/class/unfreeze-session',
      BookedSessionCustomerList :'admin/class/customer-list-with-days',
      GetNoteForPassExpire:"admin/class/pass-expire-days",
      BookedSessionCustomerListForFreeze :'admin/class/customer-list-with-days-for-freeze',
      GetCustomerContForFreeze :'admin/class/get-customer-count',
      GetSessionTransactionDetails: 'admin/class/session-transaction-details',
      DeleteSessionCustomer: 'admin/class/delete-session-customer',
      GetUserFreezeTimingsList :'admin/class/get-freezed-time-list/{Id}',
      ExportAttendance :'admin/class/export-attendance'
    },
    Yoga: {
      GetRecentSessionListByHost: 'admin/class/session-list-by-hostId',
      GetPastSessionListByHost: 'admin/class/past-session-list-by-hostId',
      GetSessionAttendanceListByHost: 'admin/class/session-attendance-list/{Id}',
      GetEventListByHost: 'admin/event/event-list-by-hostId',
      GetEventAttendanceList: 'admin/class/session-attendance-list/{Id}',
    },
    Orders : {
      GetOrderList : 'admin/order/list',
      OrderPreview : 'admin/order/details/{Id}',
      OrderEventQuestion : 'admin/order/get-question-list',
      CustomerList:'admin/customer/dropdown-list',
      GetAddOrderPassList: 'admin/order/payment-type-list',
      AddOrder: 'admin/order/save',
      EarlyCancel : 'customer/order/early-cancel-session'
    },
    ChangePass:{
      ChangePassword:  'admin/account/change-password'
    },
    Coupon:{
      InsertUpdateCoupon: 'admin/coupon/save',
      GetCouponList: 'admin/coupon/list',
      GetCouponById: 'admin/coupon/getcoupon/{Id}',
      DeleteCoupon: 'admin/coupon/delete/{Id}',
      GetAllCustomerByCoupon: 'admin/coupon/get-couponcode-detail',
      ActiveInActiveCouponCode : 'admin/coupon/active-inactive/{Id}',
      UsedCouponByCustomer : 'admin/coupon/used-coupon-code-details/{Id}' ,
      ApplyCouponCode: 'admin/order/apply-coupon-code'
    },
    ForgotPass:{
      ForgotSendEmail:'admin/account/forget-password',
      ResetPassword:'admin/account/success-forget-password'
    },
    Question:{
      InsertUpdateQuestion: 'admin/question/save',
      GetQuestionList: 'admin/question/list',
      GetQuestionById: 'admin/question/get-question/{Id}',
      DeleteQuestion: 'admin/question/delete/{Id}',
      GetQuestionTypeList: 'admin/question/question-type-list'
    },
    Event:{
      GetUserList: 'admin/user/user-dropdown-list',
      GetQuestionAnswerList: 'admin/question/question-dropdown-list',
      AddUpdateEvent: 'admin/event/save',
      AddUpdateConfirmationEvent: 'admin/event/check-event-conflict',
      GetEventList: 'admin/event/event-list', 
      GetEventCustomerList: 'admin/event/get-event-customerdetail', 
      GetEventById: 'admin/event/event-detail/{Id}',
      DeleteEvent: 'admin/event/delete-event',
      GetQuestionOptions: 'admin/event/event-question-list/{Id}',
      DeleteGalleryImage: 'admin/event/delete-event-gallery-image/{Id}',
      DeleteQuestion: 'admin/event/delete-event-question/{Id}',
      EventAttendance: 'admin/event/event-attendance-list/{Id}',
      SaveAttendance :'admin/event/save-event-attendance',
      GetEventListForCustomer: 'admin/event/event-list-by-customer',
      Export: 'admin/event/export',
      ExportEventAttendance :'admin/event/export-attendance'
    },
    Appointment:{
      AddUpdateAppointmentByAdmin:'admin/appointment/save',
      AddUpdateAppointmentByCustomer:'customer/appointment/save',
      DeleteAppointment:'',
      GetAppointmentDropdownList:'customer/appointment/appointment-type-drop-down',
      GetAppointmentListForAdmin: 'admin/appointment/appointment-list',
      GetAppointmentByIdForAdmin:'admin/appointment/get-appointment-detail/{Id}', 
      GetAppointmentByIdForCustomer:'',
      GetAppointmentListForCustomer: '',         
    },
    Settings:{
      AddPrivacyPolicy:'admin/setting/privacy-policy/save',
      GetPrivacyPolicy:'admin/setting/privacy-policy/page',
      AddTermsAndConditions: 'admin/setting/terms-condition/save',
      GetTermsAndConditions: 'admin/setting/terms-condition/page',
      AddtestiMonial:'admin/setting/testimonial-review/save',
      GetTestiMonialById: 'admin/setting/testimonial-review/{Id}',
      GetTestiMonialList: 'admin/setting/testimonial-review/list',
      DeleteTestimonialById: 'admin/setting/testimonial-review/delete/{Id}',
      AddSocialMedia: 'admin/setting/social-media/save',
      GetSocialMedia: 'admin/setting/social-media/list/{settingType}',
      AddAboutImages:'admin/setting/about-us/save',
      GetAboutImageById: 'admin/setting/about-us/about-us-image/{Id}',
      GetAboutImageList:'admin/setting/about-us/about-us-image-list',
      DeleteAboutImage: 'admin/setting/about-us/delete/{Id}',
      AddAboutSection:'admin/setting/about-us/save-about-us-section',
      GetAboutAlignmentDropdown: 'admin/setting/about-us/page-section-layout-type-dropdown-list',
      GetAboutSectionList:'admin/setting/about-us/list',
      DeleteAboutSection: 'admin/setting/about-us/delete-about-us-section/{Id}',
      GetAboutSectionById: 'admin/setting/about-us/get-about-us-section/{Id}',
      DeleteAboutSectionImage:'admin/setting/about-us/delete-about-us-section-image/{Id}'
    },
    Dashboard:{
      dashboardInfo : 'dashboard/info'
    },
    RewardCoupon:{
      CustomerList:'admin/reward-coupon-code/customer-list',
      SubmitReward: 'admin/reward-coupon-code/save',
      GetRewardCouponList: 'admin/reward-coupon-code/reward-list'
    },
    Report: {
      reportList:'admin/report/list',
      reportDetail : 'admin/report/getdetail',
      approveRejectReport : 'admin/report/approve-reject',
      refundSave: 'admin/report/refund-save',
      AddExtraSession: 'admin/report/add-extra-session'
    },
    Stripe:{
      refundInitiate: 'admin/stripe/initiate-refund'
    },
    SocialMediaLinks: {
      GetSocialMediaLinks: 'customer/setting/social-media/list/{Id}'
    },
  };
}
